@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&Pacifico&Great+Vibes&Lobster&Allura&Pinyon+Script&Kaushan+Script&Tangerine&Sacramento&Alex+Brush&display=swap');

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 10px; /* Width of the vertical scrollbar */
    height: 10px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5); /* Slightly more opaque on hover */
}


html,
body {
    height: 100%;
    margin: 0;
    background: linear-gradient(180deg, #000080, #7D1B7E);
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

.loading, 
h2 {
    font-size: 1.5em;
}

.clockStyle,
.eventDetails,
.eventTime,
.eventDateGroup,
.no-events,
.noupcoming-events {
    font-size: 1em; /* cannot change as it affects the transitions */
}


.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 0 0 10px 0;
    font-family: 'Dancing Script', cursive;
    font-size: 1.75em;
}

.footer a {
    color: inherit;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}


.hiddenEvent {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}

.fadeInEvent {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}


.afterSpacer {
    padding-left: 40px;
    margin-bottom: 10px;
    padding-bottom: 20px;
}

.eventContainer,
.groupedEventContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.eventContainer {
    padding: 5px 50px 15px 0;
}

.groupedEventContainer {
    padding: 1px 50px 5px 0; /* added 5px bottom padding */
}

.eventsList {
    overflow: hidden;
    transition: none;
}

.eventDateGroup {
    padding: 5px 0 5px 40px;
    color: #FFFFFF;
    font-style: italic;
}

h2 {
    padding: 5px 50px 0px 40px;
    position: relative;
    margin-bottom: 0;
}

h2::after {
    content: '';
    display: block;
    margin-top: 5px;
    height: 1px;
    background-color: white;
}

ul {
    margin-top: 0;
}

.eventDetails {
    flex: 4;
}

.eventDetailsLink {
    flex: 4;
    text-decoration: none; 
    color: inherit;
}

.eventDetailsLink:hover, .eventDetailsLink:active, .eventDetailsLink:focus {
    text-decoration: none;
    color: inherit;
}

.eventDetailsLink:hover strong {
    text-decoration: underline;
}

.eventTime {
    flex: 1;
    text-align: right;
}

.no-events {
    list-style-type: none;
    font-style: italic;
    padding: 5px 50px 15px 10px;
}

.noupcoming-events {
    list-style-type: none;
    font-style: italic;
    padding: 5px 50px 0 10px;
}

.clockStyle {
    height: 10px;
    padding: 10px 50px 0 0;
    text-align: right;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.event-enter, .noevent-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 0; 
    padding-bottom: 0; 
}

.event-enter-active {
    max-height: 90px; 
    opacity: 1;
    padding-top: 5px; 
    padding-bottom: 15px; 
    transition: 
        max-height 3s ease-in-out, 
        opacity 5s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}

.event-exit, .noevent-exit {
    max-height: 90px;
    opacity: 1;
    padding-top: 5px; 
    padding-bottom: 15px; 
}

.event-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0; 
    padding-bottom: 0; 
    transition: 
        max-height 5s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 5s ease-in-out, 
        padding-bottom 5s ease-in-out; 
}


.noevent-enter-active {
    max-height: 90px; 
    opacity: 1;
    padding-top: 5px;
    padding-bottom: 15px; 
    transition: 
        max-height 5s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 5s ease-in-out, 
        padding-bottom 5s ease-in-out;
}

.noevent-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: 
        max-height 3s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}


.upcomingevent-enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    padding-top: 0; 
    padding-bottom: 0; 
}

.upcomingevent-enter-active {
    max-height: 20px; 
    opacity: 1;
    padding-top: 1px; 
    padding-bottom: 1px; 
    transition: 
        max-height 1s ease-in-out, 
        opacity 3s ease-in-out, 
        padding-top 1s ease-in-out, 
        padding-bottom 1s ease-in-out; 
}

.upcomingevent-exit {
    max-height: 20px;
    opacity: 1;
    padding-top: 1px; 
    padding-bottom: 1px; 
}

.upcomingevent-exit-active {
    max-height: 0;
    opacity: 0;
    padding-top: 0; 
    padding-bottom: 0; 
    transition: 
        max-height 3s ease-in-out, 
        opacity 1s ease-in-out, 
        padding-top 3s ease-in-out, 
        padding-bottom 3s ease-in-out; 
}


@media only screen and (max-width: 768px) {
    html, body {
        overflow: auto; /* Allow scrolling on mobile */
    }

    .footer {
        font-size: 1.25em;
    }

    .eventDateGroup {
        font-size: 1em;
    }

    .eventContainer, .groupedEventContainer {
        flex-direction: column; /* Stack elements vertically on small screens */
        padding: 5px 10px; /* Adjust padding for smaller screens */
        align-items: flex-start;
    }

    .eventDetails, .eventTime {
        flex: none; /* Adjust flex settings for mobile */
        text-align: left; /* Align text to the left */
        font-size: 0.9em; /* Smaller text for mobile */
    }

    h2 {
        font-size: 1.2em; /* Adjust heading size for mobile */
        padding: 5px 10px; /* Adjust heading padding */
    }

    .clockStyle {
        padding: 10px; /* Adjust clock padding */
        text-align: center; /* Center the clock */
        font-size: 1em;
    }

    /* Override the transition effects for mobile */
    .hiddenEvent, .fadeInEvent, .event-enter, .noevent-enter, 
    .event-enter-active, .event-exit, .noevent-exit, 
    .event-exit-active, .noevent-enter-active, .noevent-exit-active,
    .upcomingevent-enter, .upcomingevent-enter-active,
    .upcomingevent-exit, .upcomingevent-exit-active {
        opacity: 1;
        visibility: visible;
        transition: none; /* Remove transition effects */
        max-height: none; /* Remove max-height restrictions */
        overflow: visible; /* Ensure content is not hidden */
        padding-top: initial; /* Reset any specific padding */
        padding-bottom: initial; /* Reset any specific padding */
    }

}